import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export const Login = () => { // Alterado para `export const`
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      console.log('Tentativa de login com:', { username, password });

      // Verifica se o login é do administrador
      if (username === 'admin') {
        console.log('Admin login bem-sucedido. Redirecionando para /admin/dashboard');
        localStorage.setItem('isAdminAuthenticated', 'true'); // Define a flag para admin
        navigate('/admin/dashboard'); // Redireciona para o painel de administração
      } else {
        // Login normal (empresa)
        const res = await axios.post('http://localhost:5001/api/empresas/login', { 
          username: username.trim(), 
          password: password.trim() 
        });
        
        console.log('Resposta do login:', res.data);
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('isAdminAuthenticated', 'false'); // Define que não é admin
        navigate('/dashboard'); // Redireciona para o painel normal de utilizadores
      }
    } catch (err) {
      console.error('Erro ao fazer login:', err);
      setError('Credenciais incorretas! Por favor, verifica o username e a password.');
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: '#212121' }}>
      <form onSubmit={handleLogin} style={{ width: '300px', padding: '20px', border: '2px solid #fff', borderRadius: '15px', backgroundColor: '#333' }}>
        <h2 style={{ color: '#fff', textAlign: 'center', marginBottom: '20px' }}>Login</h2>
        {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}
        
        {/* Caixa de Texto: Username */}
        <div style={{ marginBottom: '15px' }}>
          <label style={{ color: '#fff' }}>Username</label>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              margin: '5px 0',
              border: '2px dashed #fff',
              borderRadius: '10px',
              backgroundColor: '#555',
              color: '#fff',
            }}
          />
        </div>
        
        {/* Caixa de Texto: Password */}
        <div style={{ marginBottom: '15px' }}>
          <label style={{ color: '#fff' }}>Password</label>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              margin: '5px 0',
              border: '2px dashed #fff',
              borderRadius: '10px',
              backgroundColor: '#555',
              color: '#fff',
            }}
          />
        </div>
        
        {/* Botão de Login com transição de cor no hover */}
        <button
          type="submit"
          style={{
            width: '100%',
            padding: '10px',
            backgroundColor: '#212121', // Cor de fundo igual à da página inicial
            color: '#fff',
            border: '2px dashed #fff',
            borderRadius: '15px',
            cursor: 'pointer',
            transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
          }}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = '#444'; // Cor de fundo no hover
            e.target.style.color = '#ffcc00'; // Cor do texto no hover
            e.target.style.borderColor = '#ffcc00'; // Cor da borda no hover
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = '#212121'; // Cor de fundo padrão
            e.target.style.color = '#fff'; // Cor do texto padrão
            e.target.style.borderColor = '#fff'; // Cor da borda padrão
          }}
        >
          Login
        </button>
      </form>
    </div>
  );
};
