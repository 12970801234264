import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import HomePage from './HomePage';
import Events from './Events';
import EventPage from './EventPage';
import Instagram from './Instagram';
import TikTok from './TikTok';
import Facebook from './Facebook';
import { Sidebar } from '../components/Sidebar';


const Dashboard = () => {
  const [empresa, setEmpresa] = useState(null);
  const [nome, setNome] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmpresa = async () => {
      try {
        const token = localStorage.getItem('token');
        const res = await axios.get('http://localhost:5001/api/empresas/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setEmpresa(res.data);
        setNome(res.data.nome);
        setProfileImage(res.data.profileImage || '');
  
        // Armazenar o ID da empresa no localStorage
        localStorage.setItem('empresaId', res.data._id);
      } catch (err) {
        console.error('Erro ao buscar a empresa:', err);
        navigate('/login');
      }
    };
    fetchEmpresa();
  }, [navigate]);
  

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('nome', nome);
      if (selectedFile) {
        formData.append('profileImage', selectedFile);
      }

      const res = await axios.put(
        `http://localhost:5001/api/empresas/${empresa._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setEmpresa(res.data);
    } catch (err) {
      console.error('Erro ao atualizar as informações:', err);
    }
  };

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      {/* Barra Lateral */}
      <Sidebar empresa={empresa} navigate={navigate} />

      {/* Conteúdo Principal */}
      <div style={{ flex: 1, display: 'block', padding: '40px' }}>
        <Routes>
          <Route path="/" element={<HomePage empresa={empresa} nome={nome} setNome={setNome} profileImage={profileImage} handleSave={handleSave} setSelectedFile={setSelectedFile} />} />
          <Route path="events" element={<Events empresaId={empresa?._id} nomeEmpresa={empresa?.nome} navigate={navigate} />} />
          <Route path="events/:eventName" element={<EventPage />} />
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;
