import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Events = ({ empresaId, nomeEmpresa, navigate }) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      if (empresaId) {
        try {
          const token = localStorage.getItem('token');
          const res = await axios.get(`http://localhost:5001/api/empresas/${empresaId}/events`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setEvents(res.data);
        } catch (err) {
          console.error('Erro ao buscar eventos:', err);
        }
      }
    };
    fetchEvents();
  }, [empresaId]);

  return (
    <div style={{ textAlign: 'center' }}>
      <h1 style={{ fontSize: '32px', marginBottom: '20px' }}>Events Page</h1>
      {events.map(event => (
        <div key={event._id} style={{ margin: '20px' }}>
          <h2>{event.nome}</h2>
          <button
            onClick={() => {
              const formattedName = event.nome.toLowerCase().split(' ').join('-');
              console.log("Nome do evento formatado:", formattedName); // Verificação do nome formatado
              navigate(`/dashboard/events/${formattedName}`);
            }}
          >
            View Event
          </button>
        </div>
      ))}
    </div>
  );
};

export default Events;
