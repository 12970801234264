import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

export const PublicEventPage = () => {
  const { eventName } = useParams();
  const [newParticipant, setNewParticipant] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [statusMessage, setStatusMessage] = useState(''); // Para indicar o estado de sucesso ou erro

  const handleRegister = async () => {
    try {
      const res = await axios.post(`http://localhost:5001/api/eventos/${eventName}/register`, {
        name: newParticipant,
        email: newEmail,
      });
      console.log("Registration successful:", res.data);
      setNewParticipant('');
      setNewEmail('');
      setStatusMessage('Registration successful!'); // Mensagem de sucesso
    } catch (err) {
      console.error('Erro ao registrar participante:', err);
      setStatusMessage('Registration failed. Please try again.'); // Mensagem de erro
    }
  };

  return (
    <div style={pageStyle}>
      <h1>Register for {eventName}</h1>
      <div style={formContainerStyle}>
        <input
          type="text"
          placeholder="Nome"
          value={newParticipant}
          onChange={(e) => setNewParticipant(e.target.value)}
          style={inputStyle}
        />
        <input
          type="email"
          placeholder="Email"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          style={inputStyle}
        />
        <button onClick={handleRegister} style={buttonStyle}>Register</button>
      </div>
      {/* Exibir mensagem de status */}
      {statusMessage && <p>{statusMessage}</p>}
    </div>
  );
};

// Estilos
const pageStyle = { textAlign: 'center', padding: '20px' };
const formContainerStyle = { display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '20px' };
const inputStyle = { padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc', width: '200px' };
const buttonStyle = { padding: '10px 20px', fontSize: '16px', cursor: 'pointer', backgroundColor: '#4CAF50', color: '#fff', border: 'none', borderRadius: '5px' };
