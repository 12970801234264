import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { QRCodeSVG } from 'qrcode.react'; // Certifique-se de que a importação do QRCodeSVG está correta

const EventPage = () => {
  const { eventName } = useParams(); // Pega o nome do evento a partir da URL
  const [event, setEvent] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Pega o `empresaId` do `localStorage`
  const empresaId = localStorage.getItem('empresaId');

  useEffect(() => {
    const fetchEvent = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        console.log("Token usado:", token);
        console.log("Nome do evento a buscar:", eventName);
        console.log("ID da Empresa a buscar:", empresaId);

        // Inclui `empresaId` na URL corretamente
        const url = `http://localhost:5001/api/eventos/${empresaId}/events/${eventName}`;
        console.log("URL para buscar o evento:", url);

        const res = await axios.get(url, {
          headers: { Authorization: `Bearer ${token}` },
        });

        console.log("Resposta recebida do backend:", res.data);

        if (res.data) {
          setEvent(res.data);
          setParticipants(res.data.participants || []);
          console.log("Evento encontrado e atualizado no estado:", res.data);
        } else {
          console.log("Evento não encontrado ou resposta vazia.");
        }
      } catch (err) {
        console.error('Erro ao buscar detalhes do evento:', err);
      } finally {
        setLoading(false);
      }
    };
    fetchEvent();
  }, [eventName, empresaId]);

  if (loading) return <p>Loading...</p>;

  // Gera a URL para a página pública de inscrição no evento
  const registrationURL = `http://localhost:3000/${eventName}`;

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h1>Manage {event ? event.nome : 'Event'}</h1>
      {event ? (
        <>
          <h3>Descrição: {event.descricao}</h3>
          <h3>Data: {new Date(event.data).toLocaleDateString()}</h3>
          <h2>Participantes</h2>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {participants.map((p) => (
              <li key={p._id}>{p.name} - {p.email}</li>
            ))}
          </ul>
          <div style={{ marginTop: '20px' }}>
            {/* Botão para redirecionar para o endpoint público */}
            <button
              onClick={() => navigate(`/${eventName}`)}
              style={{ padding: '10px 20px', cursor: 'pointer', marginBottom: '20px' }}
            >
              Inscrever-se no Evento
            </button>

            {/* Centralizar o QR Code e o texto de URL */}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
              <h3>Scan para se inscrever no evento:</h3>
              {/* Renderização do QR Code com o link de inscrição */}
              <QRCodeSVG value={registrationURL} size={150} />
              <p style={{ marginTop: '10px' }}>{registrationURL} </p>
            </div>
          </div>
        </>
      ) : (
        <p>Evento nã encontrado.</p>
      )}
    </div>
  );
};

export default EventPage;
