import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('contactos'); // Estado para gerir a aba ativa
  const [contacts, setContacts] = useState([]); // Armazenar os contactos
  const [companies, setCompanies] = useState([]); // Armazenar as empresas

  // Estados para o formulário de nova empresa
  const [newCompanyName, setNewCompanyName] = useState('');
  const [newCompanyUsername, setNewCompanyUsername] = useState('');
  const [newCompanyPassword, setNewCompanyPassword] = useState('');

  // Função para logout
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isAdminAuthenticated");
    navigate("/login"); // Redireciona para o login após o logout
  };

  const removeCompany = async (companyId) => {
    try {
      console.log('DELETE', `http://localhost:5001/api/empresas/${companyId}`);
      await axios.delete(`http://localhost:5001/api/empresas/${companyId}`);
      alert('Empresa removida com sucesso!');
      fetchCompanies(); // Atualiza a lista de empresas no estado
    } catch (err) {
      console.error('Erro ao remover a empresa:', err);
      alert('Erro ao remover a empresa. Por favor, tente novamente.');
    }
  };

  // Função para carregar os contactos da base de dados
  const fetchContacts = async () => {
    try {
      const response = await axios.get('http://localhost:5001/api/contactos');
      setContacts(response.data);
    } catch (err) {
      console.error('Error fetching contacts:', err);
    }
  };

  // Função para carregar as empresas da base de dados
  const fetchCompanies = async () => {
    try {
      const response = await axios.get('http://localhost:5001/api/empresas');
      setCompanies(response.data);
    } catch (err) {
      console.error('Error fetching companies:', err);
    }
  };

  // Função para criar uma nova empresa
  const createCompany = async () => {
    try {
      console.log('Dados a enviar:', {
        nome: newCompanyName,
        username: newCompanyUsername,
        password: newCompanyPassword,
      });
      
      await axios.post('http://localhost:5001/api/empresas', {
        nome: newCompanyName,
        username: newCompanyUsername,
        password: newCompanyPassword, // Deve ser enviada como texto simples
      });
  
      alert('Empresa criada com sucesso!');
      setNewCompanyName('');
      setNewCompanyUsername('');
      setNewCompanyPassword('');
      fetchCompanies(); // Atualiza a lista de empresas no estado
  
    } catch (err) {
      console.error('Erro ao criar a empresa:', err);
      alert('Erro ao criar a empresa. Por favor, tente novamente.');
    }
  };
  

  // Carregar os dados dependendo da aba ativa
  useEffect(() => {
    if (activeTab === 'contactos') {
      fetchContacts();
    } else if (activeTab === 'empresas') {
      fetchCompanies();
    }
  }, [activeTab]);

  return (
    <div className="min-h-screen flex">
      {/* Sidebar */}
      <div className="w-64 h-full bg-gray-800 text-white p-6 flex flex-col justify-between">
        <div>
          <h1 className="text-3xl mb-8">Admin Dashboard</h1>
          {/* Botões de navegação na sidebar */}
          <button
            className={`block w-full text-left px-4 py-2 mb-2 rounded-lg ${activeTab === 'contactos' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
            onClick={() => setActiveTab('contactos')}
          >
            Contactos
          </button>
          <button
            className={`block w-full text-left px-4 py-2 mb-2 rounded-lg ${activeTab === 'empresas' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
            onClick={() => setActiveTab('empresas')}
          >
            Empresas
          </button>
        </div>
        {/* Botão de Logout */}
        <button onClick={handleLogout} className="p-4 bg-transparent border-2 border-dotted border-white rounded-lg hover:bg-gray-700">
          Logout
        </button>
      </div>

      {/* Conteúdo Principal */}
      <div className="flex-1 p-8" style={{ backgroundColor: '#212121' }}>
        {/* Título da aba ativa */}
        <h2 className="text-2xl font-bold mb-6 capitalize">{activeTab}</h2>

        {/* Conteúdo da aba ativa */}
        {activeTab === 'contactos' ? (
          <div>
            {contacts.length > 0 ? (
              <table className="w-full text-left border-collapse">
                <thead>
                  <tr>
                    <th className="border-b p-2">Nome</th>
                    <th className="border-b p-2">Email</th>
                    <th className="border-b p-2">Telefone</th>
                  </tr>
                </thead>
                <tbody>
                  {contacts.map((contact) => (
                    <tr key={contact._id}>
                      <td className="border-b p-2">{contact.name}</td>
                      <td className="border-b p-2">{contact.email}</td>
                      <td className="border-b p-2">{contact.phone || 'N/A'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>Não existem contactos.</p>
            )}
          </div>
        ) : (
          <div>
            {/* Formulário para criar uma nova empresa */}
            <div className="mb-8">
              <h3 className="text-xl font-bold mb-4">Adicionar Nova Empresa</h3>
              <input
                type="text"
                value={newCompanyName}
                onChange={(e) => setNewCompanyName(e.target.value)}
                placeholder="Nome da Empresa"
                className="p-2 bg-gray-600 text-white border border-gray-500 rounded-lg mr-2"
              />
              <input
                type="text"
                value={newCompanyUsername}
                onChange={(e) => setNewCompanyUsername(e.target.value)}
                placeholder="Username"
                className="p-2 bg-gray-600 text-white border border-gray-500 rounded-lg mr-2"
              />
              <input
                type="password"
                value={newCompanyPassword}
                onChange={(e) => setNewCompanyPassword(e.target.value)}
                placeholder="Password"
                className="p-2 bg-gray-600 text-white border border-gray-500 rounded-lg mr-2"
              />
              <button
                onClick={createCompany}
                className="p-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition duration-300"
              >
                Adicionar Empresa
              </button>
            </div>

            {/* Tabela de Empresas */}
            <div>
  {companies.length > 0 ? (
    <table className="w-full text-left border-collapse">
      <thead>
        <tr>
          <th className="border-b p-2">Nome da Empresa</th>
          <th className="border-b p-2">Username</th>
          <th className="border-b p-2">Instagram Handle</th>
          <th className="border-b p-2">Ações</th> {/* Nova coluna para ações */}
        </tr>
      </thead>
      <tbody>
        {companies.map((company) => (
          <tr key={company._id}>
            <td className="border-b p-2">{company.nome}</td>
            <td className="border-b p-2">{company.username}</td>
            <td className="border-b p-2">{company.instagramHandle || 'N/A'}</td>
            <td className="border-b p-2">
              {/* Botão de remover empresa */}
              <button
                onClick={() => removeCompany(company._id)}
                className="bg-red-600 text-white px-2 py-1 rounded-lg hover:bg-red-700 transition duration-300"
              >
                Remover
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <p>Não existem empresas.</p>
  )}
</div>

          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
