import React, { useState, useEffect, createContext, useContext, useRef } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from "react-router-dom";
import logo from "./assets/logo.png";
import { Login } from './pages/Login';
import Dashboard from "./pages/Dashboard";
import "./App.css";
import axios from "axios";
import AdminDashboard from "./pages/AdminDashboard";
import { PublicEventPage } from './pages/PublicEventPage'; // Certifique-se que o caminho está correto



const AuthContext = createContext();

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false);

  useEffect(() => {
    // Verificar o estado de autenticação ao carregar a aplicação
    const token = localStorage.getItem("token");
    const isAdmin = localStorage.getItem("isAdminAuthenticated") === "true";

    setIsAuthenticated(!!token);
    setIsAdminAuthenticated(isAdmin);
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, isAdminAuthenticated, setIsAdminAuthenticated }}>
      <Router>
        <div style={{ backgroundColor: "#212121" }} className="min-h-screen text-white">
          <Header />

          {/* Definição das Rotas */}
          <Routes>
          <Route path="/*" element={<Dashboard />} />
            <Route path="/" element={<ProtectedRoute redirectTo="/dashboard" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard/*" element={<Dashboard />} />
            <Route path="/admin/dashboard" element={<AdminRoute />} />
            <Route path="/:eventName" element={<PublicEventPage />} />
          </Routes>
        </div>
      </Router>
    </AuthContext.Provider>
  );
}
const ProtectedRoute = ({ redirectTo }) => {
  const { isAuthenticated } = useContext(AuthContext);
  return isAuthenticated ? <Navigate to={redirectTo} /> : <Home />;
};

// Componente para redirecionar com base no estado de autenticação de administrador
const AdminRoute = () => {
  // Verifica o valor diretamente do `localStorage` para decidir o redirecionamento
  if (localStorage.getItem("isAdminAuthenticated") === "true") {
    return <AdminDashboard />;
  } else {
    return <Navigate to="/login" />;
  }
};



const Header = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <header className="flex justify-between items-center p-6">
      <img
        src={logo}
        alt="Logo da Empresa"
        className="h-8 cursor-pointer filter invert"
        onClick={() => navigate("/")}
      />
      <span className="hidden md:block text-lg font-semibold italic absolute left-1/2 transform -translate-x-1/2">
  Your idea in parallel with our vision
</span>

      {!isAuthenticated && (
        <button
          onClick={() => navigate("/login")}
          className="border-2 border-dotted border-white rounded-full px-4 py-1 hover:bg-gray-800 transition duration-300"
        >
          Login
        </button>
      )}
    </header>
  );
};

// Componente da Página Inicial com a nova frase, logo e 4 caixinhas
const Home = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const contactFormRef = useRef(null); // Referência ao formulário de contacto

  // Função para lidar com a submissão do formulário
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Enviar os dados para o backend
      await axios.post("http://localhost:5001/api/contactos", {
        name,
        email,
        phone,
      });
      alert("Contact saved successfully!");

      // Limpar os campos após submissão
      setName("");
      setEmail("");
      setPhone("");
    } catch (err) {
      console.error("Error saving contact:", err);
      alert("There was an error saving your contact.");
    }
  };

  return (
    <main className="p-4 md:p-20 text-center">
      {/* Frase principal */}
      <div className="mt-4 md:mt-20">
  <h1
    className="text-4xl font-bold"
    style={{
      color: "#ecd8ba",
      fontFamily: "Latinka, sans-serif",
      fontWeight: "700",
    }}
  >
    What is our value for your company?
  </h1>
</div>

      {/* Logo abaixo da frase com espaçamento adicional */}
      <img
        src={logo}
        alt="Company Logo"
        className="h-20 mx-auto mt-12 filter invert"
      />

      {/* 4 caixinhas de serviço com estilo atualizado */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 justify-items-center mt-10">
        {/* Primeira caixinha */}
        <div className="service-box p-6">
          <span
            role="img"
            aria-label="system"
            className="text-4xl text-red-500"
          >
            🖥️
          </span>
          <p className="mt-6">
            We provide a system to stay in contact with your clients and
            maintain relationships.
          </p>
        </div>

        {/* Segunda caixinha */}
        <div className="service-box p-6">
          <span
            role="img"
            aria-label="content creation"
            className="text-4xl text-blue-500"
          >
            📷
          </span>
          <p className="mt-6">
            We create high-quality content for your business.
          </p>
        </div>

        {/* Terceira caixinha */}
        <div className="service-box p-6">
          <span
            role="img"
            aria-label="web infrastructure"
            className="text-4xl text-green-500"
          >
            💻
          </span>
          <p className="mt-6">
            We can build and manage all your web infrastructure.
          </p>
        </div>

        {/* Quarta caixinha */}
        <div className="service-box p-6">
          <span
            role="img"
            aria-label="adapt solution"
            className="text-4xl text-yellow-500"
          >
            🔧
          </span>
          <p className="mt-6">
            We adapt our solution to fit your business needs.
          </p>
        </div>
      </div>

      {/* Botão de Contact Us */}
      <div className="mt-12">
        <button
          className="border-2 border-dotted border-white rounded-full px-10 py-4 text-xl hover:bg-gray-800 transition duration-300"
          onClick={() =>
            contactFormRef.current.scrollIntoView({ behavior: "smooth" })
          }
        >
          Contact Us
        </button>
      </div>

      {/* Secção "Why Us?" */}
      <div className="mt-20 border-t-2 border-dotted border-white w-4/5 mx-auto py-12">
        <h2
          className="text-3xl font-bold mb-6"
          style={{ fontFamily: "Latinka, sans-serif" }}
        >
          Why Us?
        </h2>
        <p className="text-lg">
          We provide tailored solutions for all types of businesses, ensuring
          loyalty from your clients.
        </p>
      </div>

      {/* Cards com Degradê */}
      <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-8 justify-items-center">
        {/* Card 1: Events */}
        <div className="bg-white border-2 border-gray-300 text-black shadow-xl p-6 rounded-3xl w-64 transform hover:scale-105 transition duration-300">
          {/* Caixa interna com apenas o título */}
          <div className="bg-gray-100 p-4 rounded-xl mb-6 flex flex-col items-center">
            <h3 className="text-xl font-bold mt-2">Events</h3>
          </div>
          <p>
            We have worked with numerous event companies who rely on social
            media to promote their events and build a community.
          </p>
        </div>

        {/* Card 2: Sports */}
        <div className="bg-white border-2 border-gray-300 text-black shadow-xl p-6 rounded-3xl w-64 transform hover:scale-105 transition duration-300">
          {/* Caixa interna com apenas o título */}
          <div className="bg-gray-100 p-4 rounded-xl mb-6 flex flex-col items-center">
            <h3 className="text-xl font-bold mt-2">Sports</h3>
          </div>
          <p>
            Both teams and individual athletes need a strong digital presence to
            connect with fans and sponsors.
          </p>
        </div>

        {/* Card 3: People */}
        <div className="bg-white border-2 border-gray-300 text-black shadow-xl p-6 rounded-3xl w-64 transform hover:scale-105 transition duration-300">
          {/* Caixa interna com apenas o título */}
          <div className="bg-gray-100 p-4 rounded-xl mb-6 flex flex-col items-center">
            <h3 className="text-xl font-bold mt-2">People</h3>
          </div>
          <p>
            Any individual looking to grow their business can count on us to
            build their entire marketing infrastructure.
          </p>
        </div>
      </div>

      {/* Explicação em negrito */}
      <div className="mt-10 w-4/5 mx-auto">
        <p
          className="text-xl font-bold"
          style={{
            fontFamily: "Latinka, sans-serif",
          }}
        >
          We handle everything, from the first impression of your customer to
          when they fully embrace the culture of your company.
        </p>
      </div>

      <div className="mt-20 p-10 border-2 border-dotted border-gray-600 rounded-xl bg-[#212121] text-white w-4/5 mx-auto">
        {/* Conteúdo principal com layout flex */}
        <div className="flex flex-col md:flex-row justify-between items-start">
          {/* Título e Frase à Esquerda com Alinhamento à Esquerda */}
          <div className="flex-1 md:mr-8 text-left">
            <span className="bg-gray-800 text-white py-1 px-3 rounded-full text-sm tracking-widest">
              The goal
            </span>
            <h2 className="text-4xl font-bold mt-4">Growth is the goal,</h2>
            <h2
              className="text-4xl font-bold mb-4"
              style={{ color: "rgba(255, 255, 255, 0.4)" }}
            >
              and to grow making content is not enough anymore.
            </h2>
          </div>

          {/* Texto Explicativo à Direita */}
          <div className="flex-1 bg-[#212121] md:p-6 rounded-xl text-left">
            <p className="text-lg font-light">
              <span className="font-bold">ParalleleVision</span> help you grow.
            </p>
            <p className="text-lg mt-4 font-light">
              We’ll still make <span className="font-bold">sick content.</span>{" "}
              But then we’ll{" "}
              <span className="font-bold">find you interested leads</span>,
              manage them, nurture them, and make them your clients.
            </p>
            <p className="text-lg mt-4 font-light">
              Plus, we build the <span className="font-bold">system</span> to
              mantain those customers and keep him in line with your culture.
            </p>
          </div>
        </div>

        {/* Linha Horizontal */}
        <hr className="my-12 border-gray-600" />

        {/* Métricas */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 text-center">
          {/* Métrica 1 */}
          <div>
            <h3 className="text-6xl font-bold mb-2">3x</h3>
            <p className="text-lg font-light text-left">
              Attain <span className="font-bold">prime results.</span> We
              effortlessly thrive to boost your results always with{" "}
              <span className="font-bold">metrics</span> so we can adapt
              quickly.
            </p>
          </div>

          {/* Métrica 2 */}
          <div>
            <h3 className="text-6xl font-bold mb-2">15.6%</h3>
            <p className="text-lg font-light text-left">
              Boost Client <span className="font-bold">Closing Rate</span> by Up
              To 15.6%. Adding to a steady stream of{" "}
              <span className="font-bold">"inbound"</span> generated.
            </p>
          </div>

          {/* Métrica 3 */}
          <div>
            <h3 className="text-6xl font-bold mb-2">30%</h3>
            <p className="text-lg font-light text-left">
              By implementing <span className="font-bold">systems</span> we can
              assure your workload in the future will gain{" "}
              <span className="font-bold">30%</span> more time for other tasks.
            </p>
          </div>
        </div>
      </div>

     {/* Container para o vídeo e o formulário */}
<div className="flex flex-col md:flex-row items-center justify-between w-full bg-[#212121] text-white">
  {/* Texto e Vídeo à Esquerda */}
  <div className="w-full md:w-1/2 p-12 md:p-24 text-left">
    <h1
      className="text-5xl font-bold mb-6"
      style={{ fontFamily: "Latinka, sans-serif" }}
    >
      We will produce you Amazing content!
    </h1>
    <p className="text-xl font-light">
      But nowadays its not enough! We will also build a Network
      Infrastructure to manage your Customers Interactions
    </p>
  </div>

  {/* Vídeo com padding inferior adicional no mobile */}
  <div className="w-full md:w-1/2 h-full flex justify-center items-center p-4 mb-8 md:mb-0"> {/* mb-8 adiciona espaço apenas no mobile */}
    <div className="relative">
    <video
  className="w-96 h-auto opacity-75 border-4 border-gray-300 rounded-lg"
  src="/dia4-h264.mp4"
  loop
  playsInline
  controls // Adiciona controles para permitir que o usuário inicie manualmente
  muted
></video>

    </div>
  </div>
</div>

{/* Formulário com margem superior adicional no mobile */}
<div ref={contactFormRef} className="mt-16 md:mt-20 w-full md:w-1/2 mx-auto p-10 border-2 border-dotted border-gray-400 rounded-xl">
  <h2 className="text-3xl font-bold mb-8">Contact Us</h2>
  <form onSubmit={handleSubmit} className="flex flex-col gap-6">
    {/* Campos do formulário */}

          {/* Campo Nome */}
          <div className="flex flex-col">
            <label className="text-lg mb-2">Name</label>
            <input
              type="text"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="p-3 bg-transparent border-2 border-dotted border-gray-400 rounded-md"
            />
          </div>

          {/* Campo Email */}
          <div className="flex flex-col">
            <label className="text-lg mb-2">Email</label>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="p-3 bg-transparent border-2 border-dotted border-gray-400 rounded-md"
            />
          </div>

          {/* Campo Telefone (Opcional) */}
          <div className="flex flex-col">
            <label className="text-lg mb-2">Phone (Optional)</label>
            <input
              type="tel"
              placeholder="Enter your phone number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="p-3 bg-transparent border-2 border-dotted border-gray-400 rounded-md"
            />
          </div>

          {/* Botão de Submissão */}
          <button
            type="submit"
            className="mt-4 p-3 bg-transparent border-2 border-dotted border-white rounded-lg hover:bg-gray-700 transition duration-300"
          >
            Submit
          </button>
        </form>
      </div>
      <footer className="mt-20 py-6 border-t-2 border-dotted border-gray-400 w-4/5 mx-auto flex justify-between text-sm text-gray-500">
        <span>© 2024 – ParalleleVision</span>
        <div className="flex space-x-4">
          <a href="#privacy" className="hover:underline">
            Privacy Policy
          </a>
          <a href="#cookies" className="hover:underline">
            Cookies
          </a>
          <a href="#terms" className="hover:underline">
            Terms & Conditions
          </a>
        </div>
      </footer>
    </main>
  );
};

// Adicionar classes de estilo ao App.css
export default App;
