import React from 'react';

const HomePage = ({ empresa, nome, setNome, profileImage, handleSave, setSelectedFile }) => (
  empresa ? (
    <>
      <h1 style={{ fontSize: '36px', marginBottom: '20px' }}>Welcome to the Dashboard of the Company:</h1>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <img src={profileImage || 'https://via.placeholder.com/150'} alt="Company Profile" style={{ borderRadius: '50%', width: '150px', height: '150px', objectFit: 'cover', marginRight: '20px' }} />
        <input id="file-upload" type="file" accept="image/*" onChange={(e) => setSelectedFile(e.target.files[0])} style={{ display: 'none' }} />
        <label htmlFor="file-upload" style={{ padding: '10px 20px', backgroundColor: '#444', color: '#fff', cursor: 'pointer', borderRadius: '5px' }}>Escolher Ficheiro</label>
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input type="text" value={nome} onChange={(e) => setNome(e.target.value)} style={{ padding: '10px', width: '300px', color: '#000', backgroundColor: '#fff', border: '1px solid #ccc', borderRadius: '5px', marginRight: '20px' }} />
        <button onClick={handleSave} style={{ padding: '10px 20px', backgroundColor: 'transparent', color: '#fff', border: '2px dotted #fff', borderRadius: '10px', cursor: 'pointer' }}>Save Changes</button>
      </div>
    </>
  ) : (
    <h2>Loading...</h2>
  )
);

export default HomePage;
