import React, { useState } from 'react';

export const Sidebar = ({ empresa, navigate }) => {
  const [showSocials, setShowSocials] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isAdminAuthenticated");
    navigate("/login");
  };

  return (
    <div style={sidebarStyle}>
      <h2 style={headerStyle}>Dashboard</h2>
      <div style={buttonContainerStyle}>
        <SidebarButton text="Home" onClick={() => navigate('/dashboard')} />
        <SidebarButton text="Events" onClick={() => navigate('/dashboard/events')} />
        <SidebarButton 
          text={`Socials ${showSocials ? '▲' : '▼'}`} 
          onClick={() => setShowSocials(!showSocials)} 
        />

        {/* Submenu Socials */}
        {showSocials && (
          <div style={submenuStyle}>
            <SidebarButton text="Instagram" onClick={() => navigate('/dashboard/instagram')} isSubButton />
            <SidebarButton text="TikTok" onClick={() => navigate('/dashboard/tiktok')} isSubButton />
            <SidebarButton text="Facebook" onClick={() => navigate('/dashboard/facebook')} isSubButton />
          </div>
        )}

        <SidebarButton 
          text="Logout" 
          onClick={handleLogout} 
          styleOverride={logoutButtonStyle} 
        />
      </div>
    </div>
  );
};

// Componente para cada botão do Sidebar
const SidebarButton = ({ text, onClick, styleOverride, isSubButton }) => (
  <button
    style={{ ...menuButtonStyle, ...(isSubButton ? subButtonStyle : {}), ...styleOverride }}
    onClick={onClick}
    onMouseOver={(e) => (e.target.style.backgroundColor = '#4e4e4e')}
    onMouseOut={(e) => (e.target.style.backgroundColor = isSubButton ? '#2e2e2e' : '#3a3a3a')}
  >
    {text}
  </button>
);

// Estilos
const sidebarStyle = { width: '250px', backgroundColor: '#2e2e2e', padding: '20px', color: '#fff' };
const headerStyle = { marginBottom: '30px', color: '#fff', textAlign: 'center' };
const buttonContainerStyle = { display: 'flex', flexDirection: 'column' };
const submenuStyle = { display: 'flex', flexDirection: 'column', paddingLeft: '20px' };

const menuButtonStyle = { padding: '15px 20px', backgroundColor: '#3a3a3a', color: '#fff', cursor: 'pointer', width: '100%', borderRadius: '5px', marginBottom: '5px' };
const subButtonStyle = { padding: '10px', backgroundColor: '#2e2e2e', margin: '3px 0' };
const logoutButtonStyle = { backgroundColor: '#f44336' };

